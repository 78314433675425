<template>
  <v-row fluid elevation="10">
    <v-col cols="12">
      <v-row
        class="task_button"
        align="center"
        justify="center"
        v-ripple="{ center: true }"
      >
        <v-col cols="2" class="ma-0 pa-1"
          ><v-avatar
            :color="color_t"
            size="40"
            style="color:white;font-size:22px;"
          >{{t_num}}</v-avatar></v-col>
        <v-col cols="7" justify="left" style="position:relative;"><slot style="font-size: bolder;color:black;font-size:19px;" />
        
        <div style="position:absolute; right:1px; bottom:20px;" v-if="success">
          <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
        </div>
        <div style="position:absolute; right:1px;bottom:20px;" v-if="failed">
          <v-icon color="red">mdi-close-circle-outline</v-icon>
        </div>
        <div style="position:absolute; right:1px;bottom:20px;" v-if="moderation">
          <v-icon color="brown">mdi-alert-circle-outline</v-icon>
        </div>
        
        

        </v-col>
        <v-col cols="2">
        <v-chip 
        v-if="new_count!='0'"
        class=""
        
        color="red"
        text-color="white"
        >{{new_count}}</v-chip>
        </v-col> 
         <v-col cols="1">
        <div style="position:absolute; right:10px;bottom:20px;" v-if="1">
          <v-img src="img/s_next.png"></v-img>
        </div>
        </v-col>
      </v-row>
    </v-col>

    <v-overlay v-if="overlay"></v-overlay>
  </v-row>
</template>

<script>
export default {
  component: "task_btn",
  name: "task_btn",
  props: {
    img: {
      type: String,
      default: "",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    failed: {
      type: Boolean,
      default: false,
    },
    moderation: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    new_count:{
        type:String,
        default:"0"
    },
    t_num:{
        type:String,
        default:"0"
    }
    ,
    color_t:{
        type:String,
        default:"0"
    }
  },
  methods: {},
};
</script>