var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"margin-top":"160px"},attrs:{"fluid":""}},[(this.task1.length>0)?_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"10"}},[_c('v-card-title',{staticStyle:{"background-color":"orange","color":"white","margin-bottom":"15px"}},[_vm._v("Обов’язкові")]),_c('v-card-text',_vm._l((_vm.task1),function(card,i){return _c('v-row',{key:i},[_c('v-col',[_c('task_btn',{staticStyle:{"box-shadow":"unset !important"},attrs:{"failed":card['moderate']!='1'&&card['correct']=='0',"moderation":card['moderate']=='1',"success":card['moderate']!='1'&&card['correct']=='1',"t_num":card['data']['num_in_month'],"color_t":"#f5989d"},nativeOn:{"click":function($event){return _vm.$router.push({
                     name: _vm.links[card['data']['type']],
                     params: { routeparam: card.id },
                     })}}},[_vm._v(_vm._s(card["data"]["header"])),_c('br'),_vm._v("("+_vm._s(card['start_date'])+"-"+_vm._s(card['end_date'])+") ")])],1)],1)}),1)],1):_vm._e(),(this.task2.length>0)?_c('v-card',{staticClass:"rounded-lg",staticStyle:{"margin-top":"35px"},attrs:{"elevation":"10"}},[_c('v-card-title',{staticStyle:{"background-color":"orange","color":"white","margin-bottom":"15px"}},[_vm._v("Додаткові")]),_c('v-card-text',_vm._l((_vm.task2),function(card2,i){return _c('v-row',{key:i},[_c('v-col',[_c('task_btn',{staticStyle:{"box-shadow":"unset !important"},attrs:{"failed":card2['moderate']!='1'&&card2['correct']=='0',"success":card2['moderate']!='1'&&card2['correct']=='1',"t_num":card2['data']['num_in_month'],"color_t":"#c4df9b"},nativeOn:{"click":function($event){return _vm.$router.push({
                     name: _vm.links[card2['data']['type']],
                     params: { routeparam: card2.id },
                     })}}},[_vm._v(_vm._s(card2["data"]["header"])),_c('br'),_vm._v("("+_vm._s(card2['start_date'])+"-"+_vm._s(card2['end_date'])+") ")])],1)],1)}),1),(!_vm.nec_executed)?_c('v-overlay',{attrs:{"absolute":true}},[_vm._v(" Спочатку виконайте обов’язкові завдання "),_c('br')]):_vm._e()],1):_vm._e(),(this.task3.length>0)?_c('v-card',{staticClass:"rounded-lg",staticStyle:{"margin-top":"35px"},attrs:{"elevation":"10"}},[_c('v-card-title',{staticStyle:{"background-color":"orange","color":"white","margin-bottom":"15px"}},[_vm._v("Екстразавдання")]),_c('v-card-text',_vm._l((_vm.task3),function(card3,i){return _c('v-row',{key:i},[_c('v-col',[_c('task_btn',{staticStyle:{"box-shadow":"unset !important"},attrs:{"failed":card3['moderate']!='1'&&card3['correct']=='0',"moderation":card3['moderate']=='1',"success":card3['moderate']!='1'&&card3['correct']=='1',"t_num":card3['data']['num_in_month'],"color_t":"#c4df9b"},nativeOn:{"click":function($event){return _vm.$router.push({
                     name: _vm.links[card3['data']['type']],
                     params: { routeparam: card3.id },
                     })}}},[_vm._v(_vm._s(card3["data"]["header"])),_c('br'),_vm._v("("+_vm._s(card3['start_date'])+"-"+_vm._s(card3['end_date'])+") ")])],1)],1)}),1)],1):_vm._e(),_c('v-dialog',{model:{value:(_vm.new_type_task_info),callback:function ($$v) {_vm.new_type_task_info=$$v},expression:"new_type_task_info"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"orange","color":"white","z-index":"1","margin-bottom":"20px","justify-content":"center","text-align":"center"}},[_vm._v(" Увага! ")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"top","justify":"center"}},[_c('v-col',{attrs:{"cols":"10","sm":"4","md":"4"}},[_c('span',[_vm._v("Дорогі партнери!"),_c('br'),_vm._v(" На період військового стану вам буде надаватися можливість вибору рівня складності обов'язкових завдань. Кількість балів, що нараховується, залежить від обраного рівня. Обирати новий рівень складності можна кожні 2 тижні, а тип контракту при цьому залишається незмінним. ")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('span',{staticClass:"link",attrs:{"text":""},on:{"click":function($event){_vm.new_type_task_info = false;_vm.$router.push({name:'Tasks'})}}},[_vm._v(" До завдань ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }